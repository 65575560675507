import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

function getCurrentDate(needTime = false) {
  const d = new Date();
  let month = d.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const date = `${d.getFullYear()}-${month}-${d.getDate()}`;
  const time = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  if (needTime) return [date, time].join(' ');
  return date;
}
import { BrowseIcon, ForkIcon, UserIcon, ImageIcon, DeleteIcon, ErrorIcon, DesktopIcon } from 'tdesign-icons-vue';
import { NotifyPlugin } from 'tdesign-vue';
export default {
  name: 'App',
  components: {
    BrowseIcon,
    ForkIcon,
    UserIcon,
    ImageIcon,
    DeleteIcon,
    ErrorIcon,
    DesktopIcon
  },
  data() {
    const h = this.$createElement;
    return {
      history_data: [],
      history_columns: [{
        title: "视频名称",
        colKey: "video",
        align: "center"
      }, {
        title: "检测结果",
        colKey: "result",
        align: "center"
      }, {
        title: "概率",
        colKey: "probilition",
        align: "center"
      }
      // {
      //   title: "操作",
      //   align: "center",
      //   cell: (h, { row }) => (
      //     <div class="tdesign-table-demo__table-operations">
      //       <t-popconfirm content="确认下载吗" onConfirm={() => this.onHistoryDownloadConfirm(row)}>
      //         <t-link variant="text" hover="color" onClick={() => this.onLookUp(row)}>
      //           下载
      //         </t-link>
      //       </t-popconfirm>
      //       <t-space style="width: 6px"></t-space>
      //       <t-popconfirm content="确认删除吗" onConfirm={() => this.onHistoryDeleteConfirm(row)}>
      //         <t-link variant="text" hover="color">
      //           删除
      //         </t-link>
      //       </t-popconfirm>
      //     </div>
      //   ),
      // }
      ],

      visibleModal: false,
      isLogin: true,
      usersss: "",
      userid: "",
      userIsAdmin: false,
      paginationAffixedBottom: true,
      data: [],
      size: 'small',
      tableLayout: false,
      stripe: false,
      bordered: false,
      hover: true,
      showHeader: true,
      columns: [{
        colKey: 'applicant',
        title: '微信ID',
        align: 'center'
      }, {
        colKey: 'channel',
        title: 'Token',
        align: 'center'
      }, {
        colKey: 'status',
        title: '头像',
        align: 'center',
        cell: (h, {
          row
        }) => {
          let url = row.status;
          return (// <t-tag shape="round" theme={statusNameListMap[row.status].theme} variant="light-outline">
            //   {statusNameListMap[row.status].icon}
            //   {statusNameListMap[row.status].label}
            // </t-tag>
            h("t-avatar", {
              "attrs": {
                "image": url
              }
            })
          );
        }
      }, {
        colKey: 'username',
        title: '用户名',
        ellipsis: true,
        align: 'center'
      }, {
        colKey: 'operate',
        title: '操作',
        align: 'center',
        cell: (h, {
          row
        }) => h("div", {
          "class": "tdesign-table-demo__table-operations"
        }, [h("t-popconfirm", {
          "attrs": {
            "content": "确认删除吗"
          },
          "on": {
            "confirm": () => this.onDeleteConfirm(row)
          }
        }, [h("t-link", {
          "attrs": {
            "variant": "text",
            "hover": "color"
          }
        }, ["\u5220\u9664"])])])
      }],
      /** 非受控用法：与分页组件对齐 */
      pagination: {
        defaultCurrent: 1,
        showPageSize: false,
        defaultPageSize: 10,
        total: 3
      },
      isUseImage: false,
      isAdmin: false,
      user: "小张",
      pageSize: 21,
      images: [],
      aimages: [],
      isis: false,
      selectedRowKeys: [],
      is1: false,
      is2: false,
      avatar: "",
      name: "用户",
      sp: 1,
      files: [],
      autoUpload: true,
      display: 'file'
    };
  },
  async mounted() {
    const userId = this.$route.params.id;
    this.usersss = userId;
    console.log(userId);
    this.changeProfile(userId);
    await this.userImages(userId);
    await this.adminImages(userId);
    console.log(this.is1, this.is2);
    if (!this.is1 && !this.is2) {
      console.log("该令牌既不是用户也不是管理员");
      this.isLogin = true;
      // window.open('https://www.baidu.com/', '_self')
    }

    if (this.is2 && !this.is1) {
      this.isAdmin = true;
    }
  },
  methods: {
    async userImages(userId) {
      console.log(userId);
      await this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${userId}&do=1&param=`).then(res => {
        if (res.data.message == "用户不存在") {
          return;
        } else {
          this.images = res.data.imgs;
          this.is1 = true;
        }
      });
    },
    async adminImages(userId) {
      console.log(userId);
      let ans = false;
      await this.$axios.get(`https://violence.bytedancer.cc/v/iwrongs/?id=${userId}`).then(res => {
        if (res.data?.message == "管理员不存在") {
          this.isis = false;
          ans = false;
        } else {
          let i = 1;
          this.$axios.get(`https://violence.bytedancer.cc/v/control?id=${userId}`).then(res => {
            console.log(res.data);
            res.data.forEach(element => {
              this.data.push({
                index: i,
                applicant: element.wxid,
                channel: element.id,
                status: element.avatar,
                username: element.name
              });
              i += 1;
            });
          });
          this.isis = true;
          this.aimages = res.data.imgs;
          this.is2 = true;
          console.log(this.is2);
        }
      });
      return ans;
    },
    changeVerify() {
      this.visibleModal = !this.visibleModal;
    },
    changeProfile(userId) {
      this.$axios.get(`https://violence.bytedancer.cc/v/profile?id=${userId}`).then(res => {
        this.avatar = res.data.avatar;
        this.name = res.data.name;
      });
      this.$axios.get(`https://violence.bytedancer.cc/v/adminprofile?id=${userId}`).then(res => {
        this.avatar = res.data.avatar;
        this.name = res.data.name;
      });
    },
    changeMode() {
      if (this.isis) {
        this.isAdmin = !this.isAdmin;
        this.$axios.get(`https://violence.bytedancer.cc/v/iwrongs/?id=${this.usersss}`).then(res => {
          console.log(res.data);
          this.aimages = res.data.imgs;
        });
      } else {
        NotifyPlugin('warning', {
          title: '警告',
          content: '您不具有管理员权限。'
        });
      }
    },
    onPick(value, context) {
      console.log('onPick:', value, context);
    },
    onChange(value, context) {
      console.log('onChange:', value, context);
      if (context.trigger == "clear") {
        this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=1&param=`).then(res => {
          this.images = res.data.imgs;
        });
        return;
      }
      this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=1&param=${value[0]},${value[1]}`).then(res => {
        console.log(res);
        this.images = res.data.imgs;
      });
    },
    changeUse() {
      this.isUseImage = !this.isUseImage;
    },
    onSelectChange(selectedRowKeys, context) {
      console.log(selectedRowKeys, context);
    },
    onDeleteConfirm(row) {
      console.log(row);
      this.$refs.table.remove(row.index);
      this.$axios.post("https://violence.bytedancer.cc/v/control", {
        wxid: row.applicant,
        adminid: this.usersss
      }).then(res => {
        console.log(res);
      });
      this.$message.success('删除成功');
    },
    uploadError(img, index) {
      this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=3&param=${img.split("/")[7]}`).then(res => {
        NotifyPlugin('success', {
          title: '提示',
          content: '图片上传成功',
          duration: 1000
        });
        this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=1&param=`).then(res => {
          if (res.data.message == "用户不存在") {
            return;
          } else {
            this.images = res.data.imgs;
          }
        });
      });
    },
    deleteImage(img, index) {
      this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=2&param=${img.split("/")[7]}`).then(res => {
        NotifyPlugin('success', {
          title: '提示',
          content: '图片删除成功',
          duration: 1000
        });
        this.$axios.get(`https://violence.bytedancer.cc/v/web/?id=${this.usersss}&do=1&param=`).then(res => {
          if (res.data.message == "用户不存在") {
            return;
          } else {
            this.images = res.data.imgs;
          }
        });
      });
    },
    deleteImageAdmin(img, index) {
      this.$axios.get(`https://violence.bytedancer.cc/v/wrongs/?id=${this.usersss}&param=${img.split("/")[6]}`).then(res => {
        NotifyPlugin('success', {
          title: '提示',
          content: '图片删除成功',
          duration: 1000
        });
        this.$axios.get(`https://violence.bytedancer.cc/v/iwrongs/?id=${this.usersss}`).then(res => {
          console.log(res.data);
          this.aimages = res.data.imgs;
        });
      });
    },
    onCancelUpload() {
      console.log('cancel upload');
    },
    onRemove() {
      console.log('remove file');
    },
    // res.url 图片地址；res.uploadTime 文件上传时间；res.error 上传失败的原因
    formatResponse(res) {
      // 响应结果添加上传时间字段，用于 UI 显示
      res.uploadTime = getCurrentDate();
      this.history_data.push({
        video: res.video,
        result: res.result,
        probilition: res.probilition
      });
      console.log(res);
      return res;
    },
    onDragenter(p) {
      console.log('dragenter', p);
    },
    onDragleave(p) {
      console.log('dragleave', p);
    },
    onDrop(p) {
      console.log('drop', p);
    },
    onHistoryDeleteConfirm(row) {
      this.$refs.histable.remove(row.property);
    },
    onHistoryDownloadConfirm() {},
    onLookUp() {}
  }
};