import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import ControlPage from '@/pages/ControlPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/:id',
    name: 'ControlPage',
    component: ControlPage
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
